@mixin appear($delay: 500ms, $duration: 1000ms) {
    animation: $duration ease-in-out fade;
    animation-fill-mode: forwards;
    animation-delay: $delay;
    opacity: 0;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
