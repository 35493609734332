.platforms {
    background: $pink;
    padding: 100px 107px;
    padding-left: 81px;
    width: 610px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    grid-column-gap: 0px;
    grid-row-gap: 56px;
    justify-items: center;
    align-items: center;

    &__icon {
        width: 87px;
        height: 87px;

        &:last-child {
            margin-right: 0;
        }

        &[alt="angular"] {
            width: 87px;
            height: 98px;
        }

        &[alt="js"] {
            width: 64px;
            height: 73px;
        }

        &[alt="laravel"] {
            width: 65px;
            height: 65px;
        }

        &[alt="node"] {
            width: 123px;
            height: 114px;
        }

        &[alt="react"] {
            width: 101px;
            height: 75px;
        }

        &[alt="symfony"] {
            width: 81px;
            height: 98px;
        }

        &[alt="webgl"] {
            width: 125px;
            height: 101px;
        }

        &[alt="php"] {
            width: 85px;
            height: 101px;
        }
    }
}
