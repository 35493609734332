.carousel__dot-group {
  width: fit-content;
  margin: 0 auto;

  .carousel__dot.carousel__dot--selected > span {
    opacity: 1;
  }

  .carousel__dot {
    border: none;
    background: transparent;

    span {
      transition-duration: 0.3s;
      display: block;
      width: 10px;
      height: 10px;
      background: grey;
      border-radius: 50%;
      opacity: 0.3;
    }
  }
}
