.modal--open {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-height: 400px) {
    align-items: flex-start;
    overflow: auto;
  }

  .thank-you-message {
    display: flex;
    flex-direction: column;
    padding: 35px 62px 48px 62px;
    background: white;
    color: #314888;
    text-align: center;
    align-items: center;
    width: 382px;
    height: 400px;
    box-sizing: border-box;

    > img {
      width: 43px;
      height: 33px;
    }

    > div {
      margin: 20px 0 58px 0;

      .title {
        font-size: 18px;
        font-weight: bold;
        color: #314989;
        width: 80%;
      }

      .description {
        margin-top: 37px;

        > span {
          display: block;
          font-weight: normal;
          color: #7B85A0;
        }
      }
    }

    button {
      color: white;
      background: #314888;
      width: 150px;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      border-radius: 60px;
      padding: 16px 0;
      outline: none;
    }
  }

  .contact-us-content {
    background: white;
    padding: 45px;
    width: 100%;
    max-width: 568px;
    height: auto;
    max-height: 600px;
    display: flex;
    flex-direction: column;
    position: relative;

    &-close {
      position: absolute;
      color: #7B85A0;
      font-size: 20px;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }

    &-title {
      color: #314888;
      text-align: left;
    }

    &-button {
      background: #314888;
      width: 150px;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      border-radius: 60px;
      padding: 16px 0;
      margin-top: 40px;
    }

    &-inputs-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .input-item {
        display: flex;
        flex-direction: column;
        margin-top: 15px;

        &:first-child {
          margin-top: 0;
        }

        label {
          color: #7B85A0;
          text-align: left;
          font-size: 17px;
          padding: 14px 0;
        }

        span {
          color: red;
        }

        input {
          height: 50px;
          padding: 0 16px;
          outline: none;
          font-size: 17px;
          border-image-source: linear-gradient(to right, #6C42C5, #A42A97);
          border-width: 1px;
          border-image-slice: 1;
        }

        textarea {
          padding: 16px;
          outline: none;
          font-size: 17px;
          border-image-source: linear-gradient(to right, #6C42C5, #A42A97);
          border-width: 1px;
          border-image-slice: 1;
          resize: none;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  footer {
    .modal--open {
      position: fixed;
      top: 0;
      left: 0;
      height: auto;
      width: 100%;
      max-width: 100vw;
      min-height: 100vh;
      box-sizing: border-box;
      overflow: auto;

      .contact-us-content {
        width: 100%;
        max-height: initial;
        min-width: initial;
        max-width: initial;
        top: 0;
        left: 0;
        position: absolute;
        box-sizing: border-box;
        height: auto;

        &-button {
          margin: 40px 0;
        }
      }
    }
  }
}
