.what-we-do {
    padding-top: 130px;
    padding-bottom: 80px;
    position: relative;
    display: flex;
    align-items: center;

    @include respond-to(mobile) {
        padding: 30px 0;
    }

    @include respond-to(tablet) {
        padding: 30px 0;
    }

    @include respond-to(rotated) {
        padding: 0;
    }


    &-image {
        position: relative;
        width: 56vw;
        height: auto;
        padding-bottom: 96px;

        @include respond-to(mobile) {
            display: none;
        }

        @include respond-to(tablet) {
            display: none;
        }

        @include respond-to(rotated) {
            display: none;
        }

        &__background {
            right: 0;
            height: 630px;
            background: #00eff7;
        }

        &__content {
            position: absolute;
            width: 49vw;
            height: 600px;
            top: 96px;
            right: 0;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }

    .feature {
        margin: 102px 7vw;
        width: 30%;

        @include respond-to(mobile) {
            margin: 30px;
            width: auto;
        }

        @include respond-to(rotated) {
            margin: 30px;
            width: auto;
        }

        @include respond-to(tablet) {
            margin: 30px auto;
            width: 50vw;
        }
    }
}
