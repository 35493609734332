.keywords {
    font-size: 50px;
    color: $grey;
    line-height: 60px;

    @include respond-to(mobile) {
        font-size: 30px;
        line-height: 40px;
    }

    .react-reveal {
        &:last-child {
            .marquee {
                margin-top: 22px;

                @include respond-to(mobile) {
                    margin-top: 10px;
                }
            }
        }
    }
}
