.hero-background {
    @include appear(0ms);
    z-index: -1;
    overflow-x: hidden;

    img {
        height: 90vh;
    }

    canvas {
        @include appear(100ms);
    }
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
