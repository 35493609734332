// colors
$white: #fff;
$black: #13111c;
$cyan: #00eff7;
$pink: #e246aa;
$blue: #2c53be;
$light-purple: #94a3e2;
$purple: #314888;
$light-grey: #f4f4f4;
$grey: #93969b;
$dark-grey: #7b85a0;
$modal-background: rgba(19, 18, 29, 0.5);

// fonts
$font-family: Avenir, sans-serif;
$font-size: 17px;
$font-weight: normal;
$title-letter-spacing: 0.05rem;
$text-letter-spacing: 0.03rem;

// media queries
$break-mobile: 320px;
$break-tablet: 768px;
$break-mobile-rotated: 824px;
$break-desktop: 1024px;

// helpers
@function rem($px-size) {
    $rem-size: $px-size / $font-size;
    @return #{$rem-size}rem;
}

// media query mixins
@mixin respond-to($media) {
    @if $media == mobile {
        @media only screen and (min-width: $break-mobile) and (max-width: $break-tablet - 1) and (orientation: portrait) {
            @content;
        }
    }
        
    @else if $media == rotated {
        @media only screen and (min-width: $break-mobile) and (max-width: $break-mobile-rotated - 1) and (orientation: landscape) {
            @content
        }
    }

    @else if $media == tablet {
        @media only screen and (min-width: $break-tablet) and (max-width: $break-desktop)  {
            @content;
        }
    }

    @else if $media == desktop {
        @media only screen and (min-width: $break-desktop + 1) {
            @content;
        }
    }
}
