.slider-controls {
    position: relative;
    background: $white;
    height: 67px;
    display: flex;

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 1px;
        background: #0d4d71;
        height: 30px;
        top: 19px;
        left: 67px;
    }

    &__control {
        width: 67px;
        height: 67px;
        background-color: $white;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 0;
        transition: background 200ms ease-in-out;

        &--left {
            //background: $white;
            transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;

            &:before {
                content: ' ';
                background: url('../../assets/icon-arrow-left.svg') center center no-repeat, transparent;
                background-size: 11px;
                padding: 11px;
                position: relative;
                transition: .2s;
                left: 0;
            }

            &:hover {
                //background: $light-grey;
                box-shadow: 0 0 40px 40px $light-grey inset;
                &:before {
                    left: -3px;
                }
            }
        }

        &--right {
            //background: $white;
            transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;

            &:before {
                content: ' ';
                background: url('../../assets/icon-arrow-right.svg') center center no-repeat, transparent;
                background-size: 11px;
                padding: 11px;
                position: relative;
                transition: .2s;
                right: 0;
            }

            &:hover {
                //background: $light-grey;
                box-shadow: 0 0 40px 40px $light-grey inset;
                &:before {
                    right: -3px;
                }
            }
        }

        &--close {
            background: url('../../assets/icon-close.svg') center center no-repeat, $purple;
            background-size: 11px;
        }
    }

    &--inverted {
        background: $purple;

        &:before {
            background: $light-purple;
        }

        .slider-controls__control {
            background-color: $purple;

            &--left {
                box-shadow: 0 0 40px 40px $purple inset;

                &:before {
                    content: ' ';
                    background: url('../../assets/icon-arrow-left-light.svg') center center no-repeat, transparent;
                    background-size: 11px;
                    padding: 11px;
                    position: relative;
                    transition: .2s;
                    left: 0;
                }

                &:hover {
                    box-shadow: 0 0 40px 40px $light-purple inset;
                    &:before {
                        left: -3px;
                    }
                }
            }

            &--right {
                box-shadow: 0 0 40px 40px $purple inset;


                &:before {
                    content: ' ';
                    background: url('../../assets/icon-arrow-right-light.svg') center center no-repeat, transparent;
                    background-size: 11px;
                    padding: 11px;
                    position: relative;
                    transition: .2s;
                    right: 0;
                }

                &:hover {
                    box-shadow: 0 0 40px 40px $light-purple inset;
                    &:before {
                        right: -3px;
                    }
                }
            }
        }
    }
}
