.project-details {
  background: $white;
  width: 100%;
  height: calc(100% - 40px);
  margin: 20px;
  overflow-y: scroll;

  @include respond-to(desktop) {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row-reverse;
  }

  @include respond-to(tablet) {
  }

  @include respond-to(mobile) {
    height: 100%;
    margin: 0;
  }

  .project-details-screens {
    @include respond-to(desktop) {
      min-width: 500px;
    }

  }

  &__details {
    display: flex;
    justify-content: center;
    margin-top: 60px;

    .feature {
      width: 605px;
    }

    @include respond-to(mobile) {
      margin: 30px;
    }
  }

  &-stack-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 120px;

    @include respond-to(mobile) {
      margin: 0 30px;
      margin-bottom: 30px;
      justify-content: flex-start;
    }
  }

  &-stack {
    width: 605px;

    @include respond-to(mobile) {
      display: flex;
      flex-wrap: wrap;
      width: unset;
    }

    &__tech {
      color: $grey;
      padding: 5px 10px;
      border-radius: 10px;
      border: 1px solid $grey;

      @include respond-to(mobile) {
        margin-bottom: 10px;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }

      &:not(:first-child) {
        margin-left: 10px;

        @include respond-to(mobile) {
          margin-left: 0;
        }
      }
    }
  }
}
