.feature {
    &__title {
        margin: 28px 0 30px 0;
        font-size: 39px;

        @include respond-to(mobile) {
            font-size: 30px;
        }
    }

    &__description {
        margin: 30px 0 0 0;
        color: $dark-grey;
        line-height: 30px;

        &:first-of-type, &:first-child {
            margin-top: 0;
        }
    }

    &-description-list {
        margin-top: 30px;
        margin-bottom: 30px;
        padding-left: 0;

        li {
            list-style-type: none;
            padding-left: 15px;
            position: relative;

            &:before {
                display: inline-block;
                position: absolute;
                top: 8px;
                left: 0;
                content: '';
                width: 10px;
                height: 12px;
                margin-right: 5px;
                background: url('../../assets/icon-list-item.svg') center center no-repeat;
                background-size: contain;
            }
        }
    }

    &-header {
        @include respond-to(mobile) {
            display: flex;
        }

        &__number,
        &__divider,
        &__sub-title {
            display: inline-block;
        }

        &__number {
            color: $grey;
            font-size: 13px;
        }

        &__divider {
            width: 62px;
            height: 1px;
            background: $light-purple;
            margin: 5px 26px 5px 20px;

            @include respond-to(mobile) {
                width: 32px;
                margin-left: 10px;
                margin-right: 10px;
            }
        }

        &__sub-title {
            color: $purple;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 0.01rem;
        }
    }
}
