.modal {
    @include appear(0, 500ms);

    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background: $modal-background;
    width: 100%;
    height: 100%;
    z-index: 9999;

    &--open {
        display: flex;
        justify-content: center;
        align-content: center;
    }

    &--hide {
        animation: 500ms ease-in-out fade-out;
        animation-fill-mode: forwards;
        animation-delay: 0;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
