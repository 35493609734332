.nav {
    @include appear(600ms);

    @include respond-to(mobile) {
        display: none;
    }

    &__item {
        color: $white;
        text-transform: uppercase;
        text-decoration: none;
        font-size: rem(15px);
        padding: rem(10px) rem(20px);
        display: inline-block;
        transition: .5s;

        &:hover {
            text-shadow: 2px 2px 1px #7b9cff;
        }
    }
}
