.testimonials {
    padding-top: 70px;

    $height: 620px;

    &__list {
        padding-top: 35px;
        display: flex;
    }

    &__title {
        text-align: center;
    }

    &__placeholder {
        width: 50vw;
        height: $height;
    }

    @include respond-to(mobile) {
        padding-top: 30px;
    }

    @include respond-to(rotated) {
        padding-top: 30px;
    }

    &__photo {
        width: 50vw;
        height: $height;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        @include respond-to(tablet) {
            display: none;
        }

        @include respond-to(mobile) {
            display: none;
        }

        @include respond-to(rotated) {
            display: none;
        }

        img, & > div {
            position: absolute;
            min-height: 100%;
            width: 100%;
        }

        .slide-counter {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    &__content {
        position: relative;
    }

    &-content {
        background: $blue;
        width: 50vw;
        height: 620px;
        box-sizing: border-box;
        padding: 150px 105px;
        padding-right: 0;

        @include respond-to(tablet) {
            width: 100vw;
        }

        @include respond-to(mobile) {
            padding: 120px 30px;
            height: auto;
            width: 100vw;
        }

        @include respond-to(rotated) {
            padding: 120px 30px;
            height: auto;
            width: 100vw;
        }

        &__title {
            color: $white;
            font-size: 21px;
            font-weight: 500;
            width: 460px;
            line-height: 32px;
            margin: 0;
            margin-bottom: 30px;

            @include respond-to(mobile) {
                width: unset;
                font-size: 30px;
                line-height: 40px;
            }

            @include respond-to(tablet) {
                width: unset;
                padding-right: 120px;
            }
        }

        &__author {
            color: $white;
            font-weight: 500;
            font-size: 14px;

            span:last-child {
                color: $grey;
            }
        }
    }

    &-controls {
        position: absolute;
        top: 0;
        left: 0;
        width: 135px;
    }

    .divider {
        @include respond-to(mobile) {
            display: block;
            height: 5px;
        }
    }
}

.slide-counter {
    display: block;
    background: $white;
    width: 250px;
    box-sizing: border-box;
    padding: 25px 48px;
    font-size: 14px;
}
