.how-we-do {
    margin: 163px 0;
    margin-bottom: 0;
    display: flex;

    @include respond-to(mobile) {
        margin-top: 60px;
        flex-direction: column-reverse;
    }

    @include respond-to(tablet) {
        margin-top: 60px;
        flex-direction: column-reverse;
    }

    @include respond-to(rotated) {
        margin-top: 30px;
        flex-direction: column-reverse;
    }

    .feature {
        padding: 0 93px;
        width: auto;
        min-width: 260px;

        @include respond-to(mobile) {
            padding: 0 30px;
            box-sizing: border-box;
        }

        @include respond-to(rotated) {
            padding: 0;
            width: auto;
            margin: 0 30px;
        }

        @include respond-to(tablet) {
            padding: 0;
            width: 50vw;
            margin: 0 auto;
        }
    }

    .platforms {
        grid-gap: 56px;

        @include respond-to(mobile) {
            width: 100vw;
            margin-top: 30px;
            padding: 30px;
            grid-template-columns: auto auto;
            grid-template-rows: auto auto auto auto;
            box-sizing: border-box;
        }

        @include respond-to(rotated) {
            width: 100%;
            margin-top: 30px;
            padding: 30px 0 30px 0;
            grid-template-columns: auto auto;
            grid-template-rows: auto auto auto auto;
            box-sizing: border-box;
        }

        @include respond-to(tablet) {
            width: 100vw;
            margin-top: 30px;
            box-sizing: border-box;
        }
    }
}
