.projects {
    display: flex;

    @include respond-to(mobile) {
        position: relative;
        overflow-x: hidden;
        height: 490px;
    }

    @include respond-to(rotated) {
        position: relative;
        overflow-x: hidden;
    }

    &__controls {
        display: none;
        position: absolute;
        z-index: 8888;
        top: calc(50vh - 67px);
        right: 0;

        @include respond-to(mobile) {
            display: block;
        }

        @include respond-to(rotated) {
            top: calc(65vh - 67px);
            display: block;
        }
    }

    &__slider {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        @include respond-to(mobile) {
            height: 70vh;
        }

        @include respond-to(rotated) {
            height: 102vh;
        }

        @include respond-to(tablet) {
            .react-reveal {
                opacity: 1 !important;
            }
        }

        @include respond-to(desktop) {

            .react-reveal {
                opacity: 1 !important;
                margin-bottom: 70px;
            }
        }
    }
}

.project {
    cursor: pointer;
    position: relative;

    @include respond-to(tablet) {
      margin-bottom: 30px;
    }

    @include respond-to(mobile) {
        position: absolute;
        background: white;
    }

    @include respond-to(rotated) {
        position: absolute;
        background: white;
    }

    &__image {
        width: 518px;
        height: 326px;
        background: $light-grey;
        position: relative;
        overflow: hidden;

        @include respond-to(tablet) {
          width: 618px;
          height: 376px;
        }

        @include respond-to(mobile) {
            height: 50vh;
            width: 100vw;
            display: flex;
            justify-content: center;
        }

        @include respond-to(rotated) {
            height: 65vh;
            width: 100vw;
            display: flex;
            justify-content: center;
        }

        img {
            z-index: 5;
            position: absolute;
            min-height: 100%;
            max-width: 100%;
            background: white;

            @include respond-to(mobile) {
                object-fit: contain;
                background: black;
            }
        }
        &-cover {
          @include respond-to(desktop) {
            z-index: 10;
            background: rgba(9, 115, 171, 0.5);
            width: 100%;
            height: 100%;
            position: absolute;
            transition: 0.3s;

            &:hover {
              background: transparent;
            }
          }

        }
    }

    &__description {
        margin-top: 30px;

        @include respond-to(mobile) {
            margin-top: 30px;
        }
    }

    &-description {
        display: flex;
        justify-content: center;
        max-width: 518px;

        @include respond-to(mobile) {
            padding-left: 30px;
        }

        &__title {
            margin: 0;
            font-size: 23px;
            line-height: 22px;
            cursor: pointer;
        }

        &__details {
            padding-left: 22px;
        }

        &__stack {
            margin-top: 10px;
            margin-bottom: 0;
            width: 80%;
            color: $grey;
        }
    }
}
