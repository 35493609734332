.hero {
    background: $black;
    height: 100vh;
    position: relative;
    letter-spacing: $title-letter-spacing;

    &__nav {
        display: flex;
        justify-content: space-between;
        margin-left: 52px;
        margin-right: 158px;
        padding-top: 33px;
        pointer-events: all;

        @include respond-to(mobile) {
            margin-left: 20px;
            margin-right: 30px;
            padding-top: 20px;
        }

        @include respond-to(tablet) {
            margin-right: 52px;
        }

        @include respond-to(rotated) {
            margin-right: 30px;
            padding-top: 10px;
        }
    }

    &-content {
        position: absolute;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        user-select: none;
    }
}

@keyframes logo-shine {
    0% {
        background-position: -140px 0;
    }
    100% {
        background-position: -40px 0;
    }
}
