@font-face {
    font-family: Avenir;
    src: url('./Avenir-Book.otf') format('opentype');
    font-weight: 400;
  }

  @font-face {
    font-family: Avenir;
    src: url('./Avenir-Heavy.otf') format('opentype');
    font-weight: 500;
  }

  @font-face {
    font-family: Avenir;
    src: url('./Avenir-Black.otf') format('opentype');
    font-weight: 600;
  }
