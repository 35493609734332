.marquee {
    overflow: hidden;
    user-select: none;

    &__content {
        width: 100%;
        white-space: nowrap;
        display: -webkit-flex;
        display: flex;
        transition: transform 1s;
        cursor: pointer;

        &.dragged {
            -webkit-transition: -webkit-transform 0s !important;
            transition: -webkit-transform 0s !important;
            transition: transform 0s !important;
        }
    }

    &__text {
        animation-name: marquee;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        float: left;
        letter-spacing: $title-letter-spacing;
    }
}

@keyframes marquee {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}
