.btn-liquid {
  cursor: pointer;
  position: relative;
  width: 340px;
  height: 60px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 27px;

  pointer-events: all;

  color: #fff;
  font: 700 14px/60px Avenir, sans-serif;
  letter-spacing: 0.05em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  @include respond-to(rotated) {
    margin-top: 0;
  }

  .inner {
    position: relative;
    z-index: 2;
    height: inherit;
    line-height: 50px;

    @include respond-to(mobile) {
    }

    .hero-title__cta {
      height: 60px;
      line-height: 60px;
    }
  }

  canvas {
    position: absolute;
    top: -50px;
    right: -50px;
    bottom: -50px;
    left: -50px;

    z-index: 1;
  }
}
