.footer {
  text-align: center;
  padding: 90px 0;
  background: $black;
  color: $white;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;

  @media (max-width: 950px) {
    flex-direction: column;

    div.left {
      position: relative;
      top: 0;
      transform: none;
      width: 188px;
      margin: 0 auto 30px auto;
    }
  }

  .left {
    position: absolute;
    top: 145px;
    transform: translateX(-480px);

    .contact-us-button {
      overflow: hidden;
      position: relative;
      border-radius: 64px;
      padding: 3px;
      cursor: pointer;
      z-index: 1;

      .content {
        border-radius: 64px;
        padding: 19px 29px;
        overflow: hidden;
        position: relative;
        z-index: 10;
        background: $black;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-left: 20px;
        }
      }

      &.animated {
        &:before {
          animation: linear infinite animatedBorder 4s;
        }
      }

      &:before {
        content: '';
        background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #4304dd, #e23c99);
        background-origin: border-box;
        background-clip: content-box,border-box;
        top: -300px;
        left: -200px;
        position: absolute;
        width: 800px;
        height: 800px;
        z-index: 3;
      }

    }
  }

  &-links {
    margin-bottom: 22px;

    &__link {
      display: inline-block;
      margin: 0 11px;
      width: 48px;
      height: 48px;
      filter: brightness(0.8);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__text,
  &__text a {
    margin-top: 0;
    margin-bottom: 10px;
    color: $white;
    text-decoration: none;
    line-height: 29px;
  }

  &__copy {
    font-size: 12px;
    color: $grey;
  }
}

@keyframes animatedBorder {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
