.our-works {
    padding-top: 139px;

    @include respond-to(mobile) {
        padding-top: 60px;
    }

    @include respond-to(tablet) {
        padding-top: 60px;
    }

    @include respond-to(rotated) {
        padding-top: 30px;
    }

    &__feature {
        display: flex;
        justify-content: center;

        .feature {
            width: 602px;
            padding-left: 37px;

            @include respond-to(mobile) {
                padding: 0;
                width: auto;
                margin: 0 30px;
            }

            @include respond-to(tablet) {
                padding: 0;
                width: 50vw;
                margin: 0 auto;
            }
        }
    }

    &__projects {
        //padding-left: 191px;
        padding-bottom: 16px;
        margin-top: 106px;
        //overflow-x: scroll;
        //overflow: -moz-scrollbars-none;

        .ScrollbarCustom-trackX {
            height: 7px;
        }

        @include respond-to(mobile) {
            padding-left: 0;
            margin-top: 30px;
        }

        @include respond-to(tablet) {
            //padding-left: 60px;
            margin-top: 30px;
        }

        //&::-webkit-scrollbar {
        //    width: 0 !important;
        //}
    }
}
