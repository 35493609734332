.project-slider {
    position: relative;
    min-height: 300px;

    &__image-wrapper {
      display: none;
      height: 500px;

      @include respond-to(rotated) {
        height: 100vh;
      }

      @include respond-to(mobile) {
        display: block;
      }
    }

    &-slides {
        overflow: hidden;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background: black;

        &__slide {
            opacity: 0;
            width: 100%;
            position: absolute;

            @include respond-to(tablet) {
                width: 100%;
            }
        }
    }

    &__controls {
        position: absolute;
        top: 0;
        right: 0;
        margin: 32px;
        z-index: 2;
        border: 1px solid #0d4d71;
    }

    &__macbook {
        zoom: 50%;
        display: flex;

      @include respond-to(desktop) {
        padding-top: 300px;
      }

      @include respond-to(tablet) {
        padding-top: 100px;
        zoom: 49%;
      }

      @include respond-to(mobile) {
        display: none;
      }
    }
}
