.loader {
    width: 100vw;
    height: 100vh;
    background: $black;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
        // @include appear(0ms);

        display: block;
        content: '';
        position: static;
        width: 100px;
        height: 100px;
        border-radius: 100px;
        background: radial-gradient(circle at 140%, $pink, $purple);
        // background: linear-gradient($pink, $purple);
        animation: 800ms linear rotate infinite;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
    // from {
    //     background: linear-gradient(0deg, $pink, $purple);
    // }

    // to {
    //     background: linear-gradient(360deg, $pink, $purple);
    // }

    // 0% {
    //     transform: rotateX(0) rotateY(0);
    // }

    // 25% {
    //     transform: rotateX(180deg) rotateY(0);
    // }

    // 50% {
    //     transform: rotateX(180deg) rotateY(180deg);
    // }

    // 75% {
    //     transform: rotateX(360deg) rotateY(180deg);
    // }

    // 100% {
    //     transform: rotateX(360deg) rotateY(360deg);
    // }
    // from {
    //     transform: rotateX(0) rotateY(0);
    // }

    // to {
    //     transform: rotateX(1turn) rotateY(1turn);
    // }
}
