.about-us {
    display: flex;
    justify-content: center;
    margin-bottom: 140px;
    padding-top: 140px;

    @include respond-to(mobile) {
        padding-top: 60px;
        margin-bottom: 60px;
    }

    @include respond-to(rotated) {
        padding-top: 60px;
        margin-bottom: 60px;
    }

    .feature {
        width: 610px;
        padding-left: 100px;

        @include respond-to(mobile) {
            padding: 0;
            width: auto;
            margin: 0 30px;
        }

        @include respond-to(rotated) {
            padding: 0;
            width: auto;
            margin: 0 30px;
        }

        @include respond-to(tablet) {
            padding: 0;
            width: 50vw;
            margin: 0 auto;
        }
    }
}
