@import "../node_modules/react-typist/dist/Typist.css";

@import "./font/index.scss";
@import "./common/index.scss";
@import "./components/index.scss";

html,
body {
    background: $white;
    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-weight;
    letter-spacing: $text-letter-spacing;
    color: $purple;
    margin: 0;
    box-sizing: border-box;

    @include respond-to(mobile) {
        overflow-x: hidden;
    }

    @include respond-to(tablet) {
        overflow-x: hidden;
    }

    * {
        -webkit-tap-highlight-color: transparent;
    }
}
