@import "../../common/base";

.our-team {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 70px;

  * {
    outline: none!important;
  }

  .our-team__feature {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;

    .feature {
      width: auto;
      padding-left: 37px;

      @include respond-to(tablet) {
        padding: 0;
        width: 50vw;
        margin: 0 auto;
      }

      @include respond-to(mobile) {
        padding: 0;
        width: auto;
        margin: 0 30px;
      }
    }
  }

  .slider-photos {
    width: 100%;
    display: flex;
    justify-content: center;

    .carousel > .carousel__slider {
      height: 470px;
      width: 100vw;
    }

    .slider-control-bottomcenter {
      margin-bottom: -20px;
      @include respond-to(desktop) {
        margin-bottom: -40px;
      }
    }

    .controls-right {
      background: #314888;
      width: 67px;
      height: 67px;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 100;
      cursor: pointer;
      display: none;

      @include respond-to(desktop) {
        margin-right: -60px;
        display: flex;
      }

      svg {
        width: 12px;
      }
    }

    .controls-left {
      background: #314888;
      width: 67px;
      height: 67px;
      display: none;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 100;
      cursor: pointer;

      @include respond-to(desktop) {
        margin-left: -60px;
        display: flex;
      }

      svg {
        width: 12px;
      }
    }

    .carousel-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      &-photo {
        width: 360px;
        min-height: 540px;

        @include respond-to(mobile) {
          width: 260px;
          min-height: 390px;
        }
      }

      &-name {
        font-size: 24px;
        line-height: 40px;
        border-bottom: 1px solid #7B85A0;
        width: 100%;
        max-width: 360px;
        text-align: center;
      }

      &-position {
        color: #7C819E;
        font-size: 24px;
        line-height: 40px;
        text-align: center;
      }
    }
  }
}
