@mixin hamburger-line {
    display: block;
    position: absolute;
    left: 0;
    content: '';
    width: 40px;
    height: 1px;
    background: $white;
    transition: all 200ms ease-in-out;
}

.m-nav {
    display: none;
    position: fixed;
    right: 30px;
    z-index: 10000;

    @include respond-to(mobile) {
        display: block;
    }

    &__menu {
        width: 40px;
        height: 1px;
        margin: 20px 0;
        margin-top: 35px;
        background: $white;
        position: relative;
        transition: transform 300ms ease-in-out;
        transform: rotate(0);

        &:before {
            @include hamburger-line;
            top: -15px;
        }

        &:after {
            @include hamburger-line;
            bottom: -15px;
        }

        &--close {
            transform: rotate(135deg);
            background: $white !important;

            &:before {
                top: 0;
                transform: rotate(90deg);
                background: $white !important;
            }

            &:after {
                bottom: 0;
                background: $white !important;
            }
        }

        &--inverted {
            background: $black;

            &:before,
            &:after {
                background: $black;
            }
        }
    }
}

.m-nav-side {
    position: fixed;
    top: 0;
    right: -100vw;
    background: $black;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: all 300ms ease-in-out;
    overflow-y: scroll;

    &--open {
        right: 0;
        opacity: 1;
    }

    .nav {
        display: block;
        margin-top: 100px;
        text-align: left;
        flex: 1;
        height: 100vh;

        &__item {
            display: block;
            padding: 20px 30px;
            font-size: 21px;
        }
    }

    .footer {
        //position: fixed;
        bottom: 0;
        padding: 30px;
        width: 100%;
        box-sizing: border-box;
    }
}
