.hero-title {
    margin-left: 50%;
    margin-top: 63px;

    @include respond-to(tablet) {
        margin-left: 62px;
        margin-right: 30px;
    }

    @include respond-to(rotated) {
        margin-top: 0;
        margin-left: 36%;
    }

    @include respond-to(mobile) {
        margin-left: 30px;
        margin-top: 8vw;
    }

    &__header {
        color: $white;
        font-size: 64px;
        font-weight: bold;
        line-height: 83px;
        margin: 0;

        @include respond-to(mobile) {
            font-size: 44px;
            line-height: 15vw;
        }

        @include respond-to(rotated) {
            font-size: 32px;
            line-height: 32px;
        }
    }

    &__typing {
        display: inline-block;
        margin: 0;
        font-size: 64px;
        color: transparent;
        -webkit-text-stroke: 1px $white;

        @include respond-to(mobile) {
            font-size: 49px;
            line-height: 13vw;
            margin-top: 3px;
            width: 210px;
        }

        @include respond-to(rotated) {
            font-size: 40px;
            line-height: 9vw;
            margin-top: 3px;
        }

        @include respond-to(tablet) {
            width: 360px;
        }
    }

    &__liquid {
        display: flex;
        justify-content: center;
    }

    &__cta {
        @include appear(5000ms);

        display: inline-block;
        color: $white;
        font-weight: bold;
        font-size: 18px;
        text-decoration: none;
        padding-right: 40px;
        position: relative;

        @include respond-to(mobile) {
        }

        &:after {
            display: inline-block;
            position: absolute;
            margin-left: 25px;
            right: -30px;
            top: 22px;
            content: '';
            width: 17px;
            height: 15px;
            background: url('../../../assets/arrow.png') center center no-repeat;
            animation: 700ms ease-in-out arrow-shake infinite;
        }
    }

    &__mouse {
        margin: auto;
        position: absolute;
        left: 40px;
        top: 40%;
        bottom: 0;
        width: 50px;
        height: 50px;

        & .mouse {
            width: 34px;
            height: 55px;
            box-sizing: content-box;
            overflow: hidden;
            pointer-events: none;
            will-change: transform;
            animation: ease-in-out mouse-anim 4s infinite;

            & img {
                transform: rotate(45deg);
            }
        }
    }

    .Cursor {
        color: transparent;
        -webkit-text-stroke: 1px $white;
        font-size: 81px;
    }
}

@keyframes mouse-anim {
    0% {
        transform: translate(5vw, 0) scale(1.1);
        opacity: 0;
    }

    5% {
        opacity: 1;
    }

    30% {
        transform: translate(5vw, 0) scale(0.8);
    }

    70% {
        transform: translate(25vw, -200px) scale(0.8);
    }

    95% {
        opacity: 1;
    }

    100% {
        transform: translate(25vw, -200px) scale(1.1);
        opacity: 0;
    }
}

@keyframes mouse-button-anim {
    0% {
        background: transparent;
    }

    30% {
        background: $light-grey;
    }

    70% {
        background: $light-grey;
    }

    100% {
        background: transparent;
    }
}

@keyframes arrow-shake {
    0% {
        right: 0;
    }

    50% {
        right: -6px;
    }

    100% {
        right: 0;
    }
}
